import React from "react";
import UserContext from "components/UserContext";
import ProgressBar from "components/ProgressBar";
import Revenu from "components/Revenu";
import Questions from "components/Questions";
import QuestionsText from "components/QuestionsText";
import QuestionsCheckbox from "components/QuestionsCheckbox";
import QuestionsDetails from "components/QuestionsDetails";
import QuestionDevis from "components/QuestionDevis";
import QuestionAudit from "components/QuestionAudit";
import QuestionQ45 from "components/QuestionQ45";
import QuestionQ22 from "components/QuestionQ22";
import QuestionsTextAddress from "components/QuestionsTextAddress";
import QuestionsTextSingle from "components/QuestionsTextSingle";
import Explanation from "components/Explanation";
import Success from "components/Success";
import Greeting from "components/Greeting";
import init from "images/init.svg";
import parcours from "data/parcours";
import QR38 from "components/QR38";
import { useParams, useLocation } from "react-router-dom";
import dataRoutes from "data/dataRoutes";
import dataRoutesQ1 from "data/dataRoutesQ1";
// import dataPreQuestions from "data/partenaires/dataPreQuestions";
import TagManager from "react-gtm-module";
// import CodePromo from "components/success/CodePromo";
import getEnvironment from "utils/getEnvironment";

const CryptoJS = require("crypto-js");

function withParams(Component) {
  return (props) => (
    <Component {...props} params={useParams()} location={useLocation()} />
  );
}

const {
  apiUrl: ENDPOINT,
  cryptoKey: cryptoKEY,
  apiToken: token,
} = getEnvironment();

class Main extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      stepList: [
        "Q1",
        "Q2",
        "Q3",
        "Q4",
        "Q5",
        "Q6",
        "Q7",
        "Q8",
        "Q9",
        "Q10",
        "Q11",
        "Q12",
        "Q13",
        "Q14",
        "Q15",
        "Q16",
        "Q17",
        "Q18",
        "Q19",
        "Q20",
        // 'Q18','Q19','Q21','Q26','Q27','Q29'
        // 'Q8','Q10','Q15','Q32','Q44'
        // 'Q40','Q39','Q14'
        // 'Q5','Q48','Q26','Q36','Q10','Q18','Q21'
      ],
      currentParcours: "",
      answers: {},
      syndicContact: {
        syndicSalutation: "Madame",
        syndicMobilePhone: "",
        syndicEmail: "",
      },
      bailleurContact: {
        postalcode: "",
        street: "",
        city: "",
        state: "",
        country: "",
      },
      fiscalContact: {
        postalcode: "",
        street: "",
        city: "",
        state: "",
        country: "",
      },
      address: { postalcode: "", street: "", city: "" },
      contact: { salutation: "Madame", mobilephone: "", email: "" },
      partenairesAnswers: {
        email: "",
        agentCode: null,
      },
      partenaireMail: "",
      contactValid: false,
      addressValid: false,
      fiscalValid: false,
      syndicValid: false,
      bailleurValid: false,
      success: "RenovationGlobalesAuditDone",
      comment: "",
      revenuPeople: "1",
      revenuArea: "IDF",
      Q35: [],
      Q39: [],
      onForm: false,
      showPopup: false,
      poitou: false,
      // ⬇ Success states ⬇
      loading: true,
      error: false,
      result: "",
      patchResult: "",
      transaction_id: null,
      contact_id: null,
      simulationId: null,
      simulationCode: null,
      // ⬇ Creneau picker states ⬇
      creneau: "Matin",
      date: new Date(),
      agence: null,
      poitouResult: "",
      codePromo: "",
      resultCodePromo: {
        data: {
          montant_promo: undefined,
          reste_a_charge: undefined,
        },
      },
    };

    this.nextStep = this.nextStep.bind(this);
    this.lastStep = this.lastStep.bind(this);
    this.reset = this.reset.bind(this);
    this.callBtn = this.callBtn.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleCheckbox2 = this.handleCheckbox2.bind(this);
    // this.handleForm = this.handleForm.bind(this);
    this.handleGeneral = this.handleGeneral.bind(this);
    this.handleGeneralNoNext = this.handleGeneralNoNext.bind(this);
    this.handleSyndic = this.handleSyndic.bind(this);
    this.handleBailleurAddress = this.handleBailleurAddress.bind(this);
    this.handleFiscalAddress = this.handleFiscalAddress.bind(this);
    this.handleContact = this.handleContact.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleSiteAddress = this.handleSiteAddress.bind(this);
    this.handleRevenuArea = this.handleRevenuArea.bind(this);
    this.handleRevenuPeople = this.handleRevenuPeople.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.simulationAPI = this.simulationAPI.bind(this);
    this.patchAPI = this.patchAPI.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.runDataLayersEachPage = this.runDataLayersEachPage.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Mise à jour de l'utilisateur reçu en contexte
    const { user } = this.context || {};
    if (user?.id && prevState?.userId !== user.id) {
      this.setState({
        userId: user.id,
        contact: {
          firstname: user.firstname,
          lastname: user.name,
          email: user.email,
          mobilephone: user.telephone,
          salutation: user.title === "M." ? "Monsieur" : "Madame",
        },
      });
    }
  }

  reset = () => {
    const { embed, token } = this.context || {};
    const queryString = new URLSearchParams({ embed, token }).toString();
    window.location.href =
      window.location.origin + window.location.pathname + "?" + queryString;
  };

  callBtn = () => {
    this.runDataLayersEachPage("Q38");
    this.runDataLayers(this.state.stepList[this.state.step]);
    const SL = this.state.stepList;
    if (!SL.includes("Q38")) {
      SL.push("Q38");
    }
    this.nextURL();
    this.setState({
      stepList: SL,
      step: this.state.step + 1,
    });
  };

  getSuccess = (userAnswer) => {
    const form = { ...parcours };
    for (const question in userAnswer) {
      for (const offer in form) {
        if (
          (form[offer][question] == undefined ||
            form[offer][question].indexOf(userAnswer[question]) === -1) &&
          form[offer][question].length !== 0
        ) {
          delete form[offer];
        }
      }
    }

    const IsPorteFenetre = this.state.answers.Q49 === "Portes-fenêtres";
    this.setState({
      stepList: Object.keys(Object.values(form)[0]),
      success: IsPorteFenetre
        ? "PortesFenetres"
        : Object.values(form)[0].Success[0],
      currentParcours: Object.keys(form)[0],
    });
  };

  nextURL = () => {
    clearTimeout(this.nextStepTimeout);
    const nextURL = dataRoutes[this.state.stepList[this.state.step + 1]];
    const utmParams = {
      utm_campaign: this.state.utm_campaign,
      utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_content: this.state.utm_content,
      utm_term: this.state.utm_term,
    };
    const nextURLwithParams = Object.entries(utmParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const urlParam = nextURLwithParams ? `?${nextURLwithParams}` : "";

    const nextURLwithTracking = nextURL + `${urlParam}`;
    window.history.pushState(
      {
        step: this.state.step + 1,
        stepList: this.state.stepList,
        answers: this.state.answers,
      },
      undefined,
      nextURLwithTracking,
    );
  };

  lastURL = (ANS) => {
    const lastURL = dataRoutes[this.state.stepList[this.state.step - 1]];
    const utmParams = {
      utm_campaign: this.state.utm_campaign,
      utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_content: this.state.utm_content,
      utm_term: this.state.utm_term,
    };
    const urlParams = Object.entries(utmParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const urlParam = urlParams ? `?${urlParams}` : "";

    const lastURLwithTracking = lastURL + `${urlParam}`;

    window.history.pushState(
      {
        step: this.state.step - 1,
        stepList: this.state.stepList,
        answers: ANS,
      },
      undefined,
      lastURLwithTracking,
    );
  };

  handlePopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  resetStep = () => {
    this.setState({
      stepList: [
        "Q1",
        "Q2",
        "Q3",
        "Q4",
        "Q5",
        "Q6",
        "Q7",
        "Q8",
        "Q9",
        "Q10",
        "Q11",
        "Q12",
        "Q13",
        "Q14",
        "Q15",
        "Q16",
        "Q17",
        "Q18",
        "Q19",
        "Q20",
      ],
      onForm: false,
      showPopup: false,
    });
  };

  nextStep = () => {
    if (this.state.step < this.state.stepList.length - 1) {
      this.runDataLayersEachPage(this.state.stepList[this.state.step + 1]);
      this.runDataLayers(this.state.stepList[this.state.step]);

      TagManager.dataLayer({
        dataLayer: {
          event: "page_load",
          ...this.renderBodyData(),
        },
      });

      this.nextURL();
      this.setState({
        step: this.state.step + 1,
        onForm: false,
      });
    }
  };

  lastStep = () => {
    const ANS = this.state.answers;
    if (this.state.step !== 0) {
      const currentStep = this.state.stepList[this.state.step];
      delete ANS[currentStep];

      // switch(currentStep) {
      //     // == Reset form data while deletions in answers.
      //     case 'Q18' :
      //         this.setState({ syndicSalutation: 'Madame', syndicMobilePhone: '', syndicEmail:'' });
      //     break;
      //     case 'Q21' :
      //         this.setState({bailleurContact:  {postalcode:'', street:'', city:''} });
      //     break;
      //     case 'Q26' :
      //         this.setState({address:  {postalcode:'', street:'', city:''} });
      //     break;
      //     case 'Q27' :
      //         this.setState({fiscalContact:  {postalcode:'', street:'', city:''} });
      //     break;
      //     case 'Q29' :
      //         this.setState({contact: { salutation: 'Madame', mobilephone: '', email:''}});
      //     break;
      // }
      this.lastURL(ANS);
      this.setState({
        step: this.state.step - 1,
        answers: ANS,
      });
    }
  };

  handleCodePromo = (e) => {
    this.setState({
      codePromo: e.target.value.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, ""),
    });
  };

  handlePoitou = () => {
    const { poitou } = this.state;
    if (!poitou) {
      this.updatePoitouAPI();
    }
    this.setState({ poitou: !poitou });
  };

  validCodePromo = (e) => {
    const url = `${ENDPOINT}/codepromoparrain`;
    const bodyData = {
      transaction_id: this.state.transaction_id,
      code: this.state.codePromo,
      reste_a_charge: this.state.result.data["Reste à charge unitaire (€)"]
        ? this.state.result.data["Reste à charge unitaire (€)"]
        : undefined,
    };

    console.log("valid code", bodyData);
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
    };
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify(bodyData_encoded),
    })
      .then((result) => result.json())
      .then(
        (result) => {
          result.data = JSON.parse(
            CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
              CryptoJS.enc.Utf8,
            ),
          );
          this.setState({ resultCodePromo: result });
        },
        (error) => {
          // console.log(error);
        },
      );
  };

  // handleForm = (name, e) => {
  //     console.log('handle form from main')
  //     console.log(e);
  //     console.log(name)
  // }
  handleGeneral = (e) => {
    const ANS = this.state.answers;
    ANS[e.target.name] = e.target.value;
    this.setState({ answers: ANS });
    this.getSuccess(this.state.answers);

    // Passage à la question suivante directement en 1 clic
    if (
      (e.target.type == "radio" && e.target.name !== "Q19") ||
      (e.target.name == "Q19" && this.state.answers.Q19)
    ) {
      this.runDataLayersEachPage(this.state.stepList[this.state.step + 1]);
      this.nextStepTimeout = setTimeout(this.nextStep(), 300);
    }
  };

  handleGeneralNoNext = (e) => {
    const ANS = this.state.answers;
    ANS[e.target.name] = e.target.value;
    this.setState({ answers: ANS });
    this.getSuccess(this.state.answers);
  };

  handleCheckbox = (e) => {
    let LIST = this.state.Q35;
    const ANS = this.state.answers;
    const item = e.target.value;
    if (!LIST.includes(item)) {
      LIST.push(item);
    } else {
      LIST = LIST.filter(function (value) {
        return value != item;
      });
    }
    if (LIST.includes("isolation") && LIST.includes("chauffage")) {
      ANS[e.target.name] = "Avec Isolation et Chauffage";
      this.setState({ answers: ANS });
    } else if (LIST.includes("isolation")) {
      ANS[e.target.name] = "Avec Isolation";
      this.setState({ answers: ANS });
    } else if (LIST.includes("chauffage")) {
      ANS[e.target.name] = "Avec Chauffage";
      this.setState({ answers: ANS });
    } else if (LIST.length == 0) {
      ANS[e.target.name] = "";
      this.setState({ answers: ANS });
    } else {
      ANS[e.target.name] = "Sans isolation ou chauffage";
      this.setState({ answers: ANS });
    }
    this.setState({ Q35: LIST });
    this.getSuccess(this.state.answers);
  };

  handleCheckbox2 = (e) => {
    let LIST = this.state.Q39;
    const ANS = this.state.answers;
    const item = e.target.value;
    if (!LIST.includes(item)) {
      LIST.push(item);
    } else {
      LIST = LIST.filter(function (value) {
        return value != item;
      });
    }
    if (
      LIST.includes("Isolation de combles ou de toitures") &&
      LIST.includes("Isolation des murs")
    ) {
      ANS[e.target.name] = "Avec Combles et Murs";
      this.setState({ answers: ANS });
    } else if (LIST.includes("Isolation de combles ou de toitures")) {
      ANS[e.target.name] = "Avec Combles";
      this.setState({ answers: ANS });
    } else if (LIST.includes("Isolation des murs")) {
      ANS[e.target.name] = "Avec Murs";
      this.setState({ answers: ANS });
    } else if (LIST.length == 0) {
      ANS[e.target.name] = "";
      this.setState({ answers: ANS });
    } else {
      ANS[e.target.name] = "Sans Combles et Murs";
      this.setState({ answers: ANS });
    }
    this.setState({ Q39: LIST });
    this.getSuccess(this.state.answers);
  };

  validateCodepostal(value) {
    const Reg = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
    return Reg.test(value);
  }

  validatePhone(value) {
    return !!(value && value.replace(/[\s.]/g, "").match(/^\+?[0-9-]+$/));
  }

  validateEmail(value) {
    const emailReg =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailReg.test(value);
  }

  onPlaceSelected = (place, e) => {
    // Google place Selected
    // console.log(place);
    const current = e.dataset.name;
    const ANS = this.state.answers;
    const ADD = { street: "", city: "", postalcode: "" };
    const IDFlist = ["75", "77", "78", "91", "92", "93", "94", "95"];

    place = place.address_components;
    const street_number = place.find((e) => e.types[0] == "street_number");
    const street_name = place.find((e) => e.types[0] == "route").long_name;

    if (street_number) {
      ADD.street = street_number.long_name + " " + street_name;
    } else {
      ADD.street = street_name;
    }

    ADD.city = place.find((e) => e.types[0] == "locality").long_name;
    ADD.postalcode = place.find((e) => e.types[0] == "postal_code").long_name;
    const isIDF = IDFlist.includes(ADD.postalcode.slice(0, 2));
    ANS[current] = ADD;
    const FILLED = Object.values(this.state.answers[current]).filter(
      (element) => {
        return element !== "";
      },
    );

    this.setState({
      answers: ANS,
    });

    switch (current) {
      case "Q21":
        return this.setState({
          bailleurContact: ADD,
          bailleurValid:
            FILLED.length == 3 &&
            this.validateCodepostal(this.state.answers.Q21.postalcode),
          bailleurShow: true,
        });
      case "Q26":
        return this.setState({
          address: ADD,
          addressValid:
            FILLED.length == 3 &&
            this.validateCodepostal(this.state.answers.Q26.postalcode),
          revenuArea: isIDF ? "IDF" : "Autres",
          addressShow: true,
        });
      case "Q27":
        return this.setState({
          fiscalContact: ADD,
          fiscalValid:
            FILLED.length == 3 &&
            this.validateCodepostal(this.state.answers.Q27.postalcode),
          revenuArea: isIDF ? "IDF" : "Autres",
          fiscalShow: true,
        });
    }
  };

  handlePartenaires = (e) => {
    let ANS = this.state.partenaireMail;
    ANS = e.target.value;

    const FILLED = Object.values(this.state.partenairesAnswers).filter(
      (element) => {
        return element !== "";
      },
    );

    this.setState({
      onForm: FILLED.length == 1,
      partenaireMail: ANS,
      valide:
        FILLED.length == 1 && this.validateEmail(this.state.partenaireMail),
    });
  };

  handleLaForetAgent = (item) => {
    this.setState({
      agence: item,
    });
    // console.log(this.state.agence ? this.state.agence['value'] : null)
  };

  handleSyndic = (e) => {
    const ANS = this.state.answers;
    const SYN = this.state.syndicContact;
    SYN[e.target.name] = e.target.value;
    if (
      e.target.name == "syndicFirstname" ||
      e.target.name == "syndicLastname"
    ) {
      SYN[e.target.name] =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    ANS.Q18 = SYN;

    const FILLED = Object.values(this.state.answers.Q18).filter((element) => {
      return element !== "";
    });

    this.setState({
      onForm: FILLED.length == 5,
      syndicContact: SYN,
      answers: ANS,
      syndicValid:
        FILLED.length == 5 &&
        this.validatePhone(this.state.answers.Q18.syndicMobilephone) &&
        this.validateEmail(this.state.answers.Q18.syndicEmail),
    });
  };

  handleComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleContact = (e) => {
    const ANS = this.state.answers;
    const CONTACT = this.state.contact;
    CONTACT[e.target.name] = e.target.value;
    if (e.target.name == "firstname" || e.target.name == "lastname") {
      CONTACT[e.target.name] =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }
    ANS.Q29 = CONTACT;

    const FILLED = Object.values(this.state.answers.Q29).filter((element) => {
      return element !== "";
    });
    this.setState({
      onForm: FILLED.length == 5,
      contact: CONTACT,
      answers: ANS,
      contactValid:
        FILLED.length == 5 &&
        this.validatePhone(this.state.answers.Q29.mobilephone) &&
        this.validateEmail(this.state.answers.Q29.email),
    });
  };

  handleContactAutofill = () => {
    const ANS = this.state.answers;
    const CONTACT = this.state.contact;
    const FILLED = Object.values(this.state.contact).filter((element) => {
      return element !== "";
    });
    ANS.Q29 = CONTACT;
    this.setState({
      onForm: FILLED.length == 5,
      contact: CONTACT,
      answers: ANS,
      contactValid:
        FILLED.length == 5 &&
        this.validatePhone(this.state.contact.mobilephone) &&
        this.validateEmail(this.state.contact.email),
    });
  };

  handleBailleurAddress = ({
    isValid,
    country,
    street,
    city,
    postalcode,
    state,
  }) => {
    const ANS = this.state.answers;
    const BAI = { country, street, city, postalcode, state };
    ANS.Q21 = BAI;

    this.setState({
      onForm: isValid,
      bailleurContact: BAI,
      answers: ANS,
      bailleurShow: true,
      bailleurValid: isValid,
    });
  };

  handleFiscalAddress = ({
    isValid,
    country,
    street,
    city,
    postalcode,
    state,
    isIDF,
  }) => {
    const ANS = this.state.answers;
    const FIS = { country, street, city, postalcode, state };
    ANS.Q27 = FIS;

    this.setState({
      onForm: isValid,
      fiscalContact: FIS,
      fiscalShow: true,
      answers: ANS,
      fiscalValid: isValid,
      revenuArea: isIDF ? "IDF" : "Autres",
    });
  };

  handleSiteAddress = ({ isValid, street, city, postalcode, isIDF }) => {
    const ANS = this.state.answers;
    const ADD = { street, city, postalcode };
    ANS.Q26 = ADD;

    this.setState({
      onForm: isValid,
      address: ADD,
      addressShow: true,
      answers: ANS,
      addressValid: isValid,
      revenuArea: isIDF ? "IDF" : "Autres",
    });
  };

  handleRevenuPeople = (e) => {
    const ANS = this.state.answers;
    ANS.Q19 = "";
    this.setState({ revenuPeople: e.target.value, answers: ANS });
  };

  handleRevenuArea = (e) => {
    const ANS = this.state.answers;
    ANS.Q19 = "";
    this.setState({ revenuArea: e.target.value, answers: ANS });
  };

  handleFile = (e) => {
    // console.log('e', e);
    let content = e.target.result;
    content = content.split(",")[1];
    // console.log('file content',  content)
    this.setState({ file: content });
  };

  handleFileDevis = (e) => {
    let content = e.target.result;
    content = content.split(",")[1];
    // console.log('file content',  content)
    this.setState({ fileDevis: content });
  };

  handleFileAudit = (e) => {
    let content = e.target.result;
    content = content.split(",")[1];
    this.setState({ fileAudit: content });
  };

  handleDate = (date) => {
    // console.log(date)
    this.setState({ date });
  };

  handleCreneau = (e) => {
    this.setState({ creneau: e.target.value });
  };

  renderBodyData(offre) {
    let IsIDF = this.state.revenuArea == "IDF";
    let NombreFoyer = this.state.revenuPeople;
    const SL = this.state.stepList;
    if (!SL.includes("Q19")) {
      IsIDF = undefined;
      NombreFoyer = undefined;
    }

    let typeOffre = this.state.answers.Q1;
    if (typeOffre == "renovationGlobale") {
      typeOffre = "auditEnergetique";
    }

    let TypeTravaux = this.state.answers.Q4;
    if (TypeTravaux == "garage" || TypeTravaux == "vide") {
      TypeTravaux = "Isolation d'un plancher";
    }

    const typeOffre_prime =
      typeOffre == "isolation"
        ? "Fenêtre"
        : typeOffre == "chauffeEau"
          ? "Chauffe-eau thermodynamique"
          : this.state.answers.Q45;

    const typeChaudiere = [
      this.state.answers.Q23,
      this.state.answers.Q24,
      this.state.answers.Q25,
      this.state.answers.Q34,
    ];
    const typeChaudiereResult = typeChaudiere.filter((element) => {
      return element !== undefined;
    });
    if (
      this.state.answers.Q22 === "Charbon" &&
      typeChaudiereResult.length === 0
    ) {
      typeChaudiereResult.push("Chaudière à charbon");
    }
    const typeCombles = [
      this.state.answers.Q5,
      this.state.answers.Q6,
      this.state.answers.Q7,
    ];
    const typeComblesResult = typeCombles.filter((element) => {
      return element !== undefined;
    });

    const RG_Isolation = [
      ...this.state.Q39,
      this.state.answers.Q5,
      this.state.answers.Q6,
    ];
    const RG_IsolationResult = RG_Isolation.filter((element) => {
      return element !== undefined;
    });

    const { token } = this.context || { token: "" };

    return {
      typeOffre,
      Type_de_logement__c: this.state.answers.Q2,
      ageLogement: this.state.answers.Q3,
      Type_de_travaux__c: TypeTravaux,
      Type_de_combles__c: typeComblesResult[0],
      Surface_chauffee__c: this.state.answers.Q8,
      vehiculeRechargeable: this.state.answers.Q9,
      Modele_equipement__c: this.state.answers.Q10,
      Puissance_installation__c: this.state.answers.Q11,
      Distance__c: this.state.answers.Q12,
      Audit_pr_vu__c: this.state.answers.Q14,
      Mensualite__c: this.state.answers.Q15,
      Type_de_contrat__c: this.state.answers.Q16,
      typeProjet: this.state.answers.Q17,
      Nombre_de_personne_dans_le_foyer__c: NombreFoyer,
      Revenu_fiscal__c: this.state.answers.Q19,
      Situation_de_l_occupant__c: this.state.answers.Q20,
      Energie_de_chauffage_principale__c: this.state.answers.Q22,
      Type_de_chaudiere_a_remplacer__c: typeChaudiereResult[0],
      Adresse_du_chantier__c: this.state.address.street,
      Code_Postal_du_chantier__c: this.state.address.postalcode,
      Ville_du_chantier__c: this.state.address.city,
      street: this.state.fiscalContact.street
        ? this.state.fiscalContact.street
        : this.state.address.street,
      city: this.state.fiscalContact.city
        ? this.state.fiscalContact.city
        : this.state.address.city,
      postalcode: this.state.fiscalContact.postalcode
        ? this.state.fiscalContact.postalcode
        : this.state.address.postalcode,
      state: this.state.fiscalContact.state
        ? this.state.fiscalContact.state
        : this.state.address.state,
      country: this.state.fiscalContact.country
        ? this.state.fiscalContact.country
        : this.state.address.country,
      Travaux_prevus__c: this.state.answers.Q28,
      salutation: this.state.contact.salutation,
      firstname: this.state.contact.firstname,
      lastname: this.state.contact.lastname,
      mobilephone: this.state.contact.mobilephone,
      email: this.state.contact.email,
      typeSimulation: this.state.answers.Q30,
      parrainage_code_parrain__c: this.state.answers.Q32,
      Salutation: this.state.syndicContact.salutation,
      Nom_du_gestionnaire_du_syndic__c: this.state.syndicContact.syndicLastname,
      Prenom_du_gestionnaire_du_syndic__c:
        this.state.syndicContact.syndicFirstname,
      Telephone_du_syndic__c: this.state.syndicContact.syndicMobilephone,
      Email_du_syndic__c: this.state.syndicContact.syndicEmail,
      Int_ress_par_d_autres_FOST__c: this.state.Q35,
      RG_Isolation: RG_IsolationResult,
      RG_Chauffage: this.state.answers.Q40,
      NewsletterHellio: this.state.answers.Q33,
      IsIDF,
      utm_campaign: this.state.utm_campaign,
      utm_content: this.state.utm_content,
      utm_medium: this.state.utm_medium,
      utm_source: this.state.utm_source,
      utm_term: this.state.utm_term,
      leadsource: this.state.leadsource
        ? this.state.leadsource
        : this.props.marque,
      montant_travaux: this.state.answers.Q43,
      surface_des_combles_m2_c: this.state.answers.Q44,
      typeOffre_prime,
      nombre_c: this.state.answers.Q46,
      surface_chauffee__c: this.state.answers.Q47,
      type_audit: this.state.answers.Q48,
      type_fenetre: this.state.answers.Q49,
      usage: this.state.answers.Q51,
      Remplacement_ancien_equipement__c: this.state.answers.Q50,
      magasin_lm__c: this.state.agence ? this.state.agence.value : null,
      email_de_l_interm_diaire__c: this.state.partenaireMail,
      token,
      contactId: this.state.contact_id,
      transactionId: this.state.transaction_id,
      transaction_document_leads: this.state.docLeads,
    };
  }

  simulationAPI = () => {
    const fileDevisUpload =
      this.state.fileDevis && this.state.fileDevis.length <= 700000
        ? this.state.fileDevis
        : undefined;
    const fileAuditUpload =
      this.state.fileAudit && this.state.fileAudit.length <= 700000
        ? this.state.fileAudit
        : undefined;
    const fileImpositionUpload =
      this.state.file && this.state.file.length <= 700000
        ? this.state.file
        : undefined;
    const bodyData = this.renderBodyData(this.state.success);
    const updateBodyData = {
      ...bodyData,
    };
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
      fileImposition: this.state.file,
      fileDevis: this.state.fileDevis,
      fileAudit: this.state.fileAudit,
    };
    const updateBodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(updateBodyData),
        cryptoKEY,
      ).toString(),
      fileImposition: this.state.file,
      fileDevis: this.state.fileDevis,
      fileAudit: this.state.fileAudit,
    };
    if (!this.state.transaction_id) {
      const url = `${ENDPOINT}/getinfos`;

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token,
        },
        body: JSON.stringify(bodyData_encoded),
      })
        .then((result) => result.json())
        .then((result) => {
          result.data = JSON.parse(
            CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
              CryptoJS.enc.Utf8,
            ),
          );
          // console.log('API result', result);
          this.setState({
            loading: false,
            error: false,
            result,
            transaction_id: result.data.hubspot_transactionId,
            contact_id: result.data.hubspot_contactId,
            simulationId: result.data.simulationId,
            simulationCode: result.data.simulationCode,
            transaction_document_leads:
              result.data.hubspot_transaction.properties.document_leads,
          });
        })
        .catch((error) => {
          console.warn("simulationAPI", error.message);
          this.setState({
            loading: false,
            error: true,
            errorMessage: error.message,
          });
        });
    } else {
      // Update simulation
      this.setState({
        loading: true,
      });
      const url = `${ENDPOINT}/updatesimulation`;
      fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token,
        },
        body: JSON.stringify(updateBodyData_encoded),
      })
        .then((result) => result.json())
        .then((result) => {
          result.data = JSON.parse(
            CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
              CryptoJS.enc.Utf8,
            ),
          );
          // console.log('UpdateSimulationResult', result);
          this.setState({
            loading: false,
            error: false,
            result,
            transaction_id: result.data.hubspot_transactionId,
            contact_id: result.data.hubspot_contactId,
            simulationId: result.data.simulationId,
            simulationCode: result.data.simulationCode,
            transaction_document_leads:
              result.data.hubspot_transaction.properties.document_leads,
          });
        })
        .catch((error) => {
          console.warn("simulationAPI", error.message);
          this.setState({
            loading: false,
            error: true,
            errorMessage: error.message,
          });
        });
    }
  };

  patchAPI() {
    const bodyData = {
      transaction_id: this.state.result.data.hubspot_transactionId,
      transaction_description:
        this.state.result.data.hubspot_transaction.properties.description,
      transaction_document_leads:
        this.state.result.data.hubspot_transaction.properties.document_leads,
      user_indications: this.state.comment,
      fileImposition: this.state.file,
      user_firstname: this.state.contact.firstname,
      user_lastname: this.state.contact.lastname,
      date_disponibilite_rappel: this.state.date.toLocaleDateString("fr"),
      creneau_disponibilite_rappel: this.state.creneau,
      partenariat_poitou: this.state.poitou,
    };
    this.setState({ patchBody: bodyData });
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
    };
    // console.log(bodyData_encoded)
    // body
    const url = `${ENDPOINT}/updatetransaction`;

    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify(bodyData_encoded),
    })
      .then((result) => result.json())
      .then(
        (result) => {
          result.data = JSON.parse(
            CryptoJS.AES.decrypt(result.data, cryptoKEY).toString(
              CryptoJS.enc.Utf8,
            ),
          );
          this.setState({ patchResult: result });
        },
        (error) => {
          // console.log(error);
        },
      );
  }

  runDataLayers(question) {
    if (this.dataLayers(question)) {
      const tagManagerArgs = {
        dataLayer: this.dataLayers(question),
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }

  runDataLayersEachPage(question) {
    const tagManagerArgs = {
      dataLayer: {
        event: "Question",
        pageUrl: dataRoutes[question],
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }

  dataLayers(question) {
    switch (question) {
      case "Q1":
        return {
          event: "Home",
          pageUrl: dataRoutes[question],
          typeOffre: this.state.answers.Q1,
          formType: "Simulateur",
        };

      case "Q2":
        return {
          event: "Logement",
          pageUrl: dataRoutes[question],
          userHabitation: this.state.answers.Q2,
        };

      case "Q17":
        return {
          event: "Type Logement",
          pageUrl: dataRoutes[question],
          hellioOffre: this.state.answers.Q17,
        };

      case "Q20":
        return {
          event: "Type Proprietaire",
          pageUrl: dataRoutes[question],
          userProprietaire: this.state.answers.Q20,
        };
      case "Q4":
        return {
          event: "Type Isolation",
          pageUrl: dataRoutes[question],
          type_isolation: this.state.answers.Q4,
        };
      case "Q5":
        return {
          event: "Type Combles",
          pageUrl: dataRoutes[question],
          userCombles: this.state.answers.Q5,
        };
      case "Q6":
        return {
          event: "Murs",
          pageUrl: dataRoutes[question],
          type_isolation: this.state.answers.Q6,
        };
      case "Q7":
        return {
          event: "Type Nouveau Chauffage",
          pageUrl: dataRoutes[question],
          userChauffage: this.state.answers.Q7,
        };

      case "Q22":
        return {
          event: "Energie",
          pageUrl: dataRoutes[question],
          userEnergie: this.state.answers.Q22,
        };

      case "Q25":
        return {
          event: "Type Equipement",
          pageUrl: dataRoutes[question],
          userEnergieEquipement: this.state.answers.Q25,
        };

      case "Q26":
        return {
          event: "Adresse Travaux",
          pageUrl: dataRoutes[question],
          userCodePostal: this.state.address.postalcode,
        };

      case "Q27":
        return {
          event: "Adresse Fiscale",
          pageUrl: dataRoutes[question],
          userCodePostalFiscale: this.state.fiscalContact.postalcode,
        };

      case "Q29":
        return {
          event: "Lead",
          pageUrl: dataRoutes[question],
          userEmail: this.state.contact.email,
        };

      case "Q30":
        return {
          event: "Type Devis",
          pageUrl: dataRoutes[question],
          accompagnementDevis: this.state.answers.Q30,
        };
      case "Success":
        return {
          event: "Devis Etre Rappele - Simulateur Particulier",
          pageUrl: dataRoutes[question],
        };
      // default :
      //     return {
      //         event: ' Question',
      //         pageUrl: dataRoutes[question],
      //     }
    }
  }

  renderLead(current) {
    switch (current) {
      default:
      case "Q1":
      case "Q2":
      case "Q3":
      case "Q4":
      case "Q6":
      case "Q7":
      case "Q9":
      case "Q11":
      case "Q12":
      case "Q13":
      case "Q16":
      case "Q17":
      case "Q20":
      case "Q23":
      case "Q24":
      case "Q25":
      case "Q28":
      case "Q30":
      case "Q31":
      case "Q33":
      case "Q34":
      case "Q40":
      case "Q49":
      case "Q50":
      case "Q51":
        return (
          <Questions
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
          ></Questions>
        );
      case "Q22":
        return (
          <QuestionQ22
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
            Q45={this.state.answers.Q45}
          ></QuestionQ22>
        );
      case "Q45":
        return (
          <QuestionQ45
            name={current}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            leadsource={this.state.leadsource}
            lastStepPrequestion={this.lastStepPrequestion}
            Q1={this.state.answers.Q1}
          ></QuestionQ45>
        );
      case "Q14":
        return (
          <QuestionAudit
            name={current}
            handleData={this.handleGeneralNoNext}
            data={this.state.answers[current]}
            handleFile={this.handleFileAudit}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            file={this.state.fileAudit}
          ></QuestionAudit>
        );
      case "Q5":
      case "Q48":
        return (
          <QuestionsDetails
            name={current}
            handleData={this.handleGeneral}
            handleData2={this.handleGeneralNoNext}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsDetails>
        );
      case "Q8":
      case "Q10":
      case "Q15":
      case "Q32":
      case "Q44":
      case "Q46":
      case "Q47":
        return (
          <QuestionsTextSingle
            name={current}
            userContact={this.state.answers}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsTextSingle>
        );

      case "Q18":
        return (
          <QuestionsText
            name="Q18"
            userContact={this.state.syndicContact}
            handleData={this.handleSyndic}
            data={this.state.syndicValid}
            salutation={this.state.syndicContact.syndicSalutation}
            step={this.state.step}
            nextStep={this.nextStep}
            onForm={this.state.onForm}
            lastStep={this.lastStep}
            phoneValid={this.validatePhone(
              this.state.syndicContact.syndicMobilephone,
            )}
            emailValid={this.validateEmail(
              this.state.syndicContact.syndicEmail,
            )}
            dataItems={this.props.dataItems}
          ></QuestionsText>
        );
      case "Q19":
        return (
          <Revenu
            name="Q19"
            handleData={this.handleGeneral}
            handleRevenuPeople={this.handleRevenuPeople}
            handleRevenuArea={this.handleRevenuArea}
            revenuPeople={this.state.revenuPeople}
            revenuArea={this.state.revenuArea}
            data={this.state.answers.Q19}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            globalState={this.state}
          ></Revenu>
        );

      case "Q21":
        return (
          <QuestionsTextAddress
            international
            userContact={this.state.bailleurContact}
            handleAddress={this.handleBailleurAddress}
            data={this.state.bailleurValid}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
          />
        );

      case "Q26":
        return (
          <QuestionsTextAddress
            userContact={this.state.address}
            handleAddress={this.handleSiteAddress}
            data={this.state.addressValid}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
          />
        );

      case "Q27":
        return (
          <QuestionsTextAddress
            international
            userContact={this.state.fiscalContact}
            handleAddress={this.handleFiscalAddress}
            data={this.state.fiscalValid}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
          />
        );

      case "Q29":
        return (
          <QuestionsText
            name="Q29"
            userContact={this.state.contact}
            handleData={this.handleContact}
            data={this.state.contactValid}
            salutation={this.state.contact.salutation}
            handleAutofill={this.handleContactAutofill}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            onForm={this.state.onForm}
            phoneValid={this.validatePhone(this.state.contact.mobilephone)}
            emailValid={this.validateEmail(this.state.contact.email)}
            dataItems={this.props.dataItems}
            readOnly={!!this.context?.user?.id}
          ></QuestionsText>
        );
      case "Q35":
        return (
          <QuestionsCheckbox
            name={current}
            handleData={this.handleCheckbox}
            data={this.state.Q35}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsCheckbox>
        );
      case "Q39":
        return (
          <QuestionsCheckbox
            name={current}
            handleData={this.handleCheckbox2}
            data={this.state.Q39}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></QuestionsCheckbox>
        );
      case "Q36":
      case "Q37":
        return (
          <Explanation
            name={current}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
          ></Explanation>
        );
      case "Q38":
        return (
          <QR38
            patchAPI={this.patchAPI}
            lastStep={this.lastStep}
            logoEtreRappele={this.props.logoEtreRappele}
            dataItems={this.props.dataItems}
          ></QR38>
        );
      case "Q43":
        return (
          <QuestionDevis
            name={current}
            userContact={this.state.answers}
            handleData={this.handleGeneral}
            data={this.state.answers[current]}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            dataItems={this.props.dataItems}
            handleFile={this.handleFileDevis}
          ></QuestionDevis>
        );

      case "Success":
        return (
          <Success
            {...this.state}
            name="Success"
            success={this.state.success}
            handleData={this.handleComment}
            callBtn={this.callBtn}
            comment={this.state.comment}
            answers={this.state.answers}
            reset={this.reset}
            simulationAPI={this.simulationAPI}
            handleFile={this.handleFile}
            loading={this.state.loading}
            error={this.state.error}
            result={this.state.result}
            Q2={this.state.answers.Q2}
            Q10={this.state.answers.Q10}
            Q11={this.state.answers.Q11}
            Q12={this.state.answers.Q12}
            Q13={this.state.answers.Q13}
            Q16={this.state.answers.Q16}
            facture={this.state.answers.Q15}
            Q19={this.state.answers.Q19}
            Q22={this.state.answers.Q22}
            Q4={this.state.answers.Q4}
            Q48={this.state.answers.Q48}
            surface={this.state.answers.Q8}
            step={this.state.step}
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            showFile={this.state.stepList.includes("Q43")}
            creneau={this.state.creneau}
            date={this.state.date}
            handleDate={this.handleDate}
            handleCreneau={this.handleCreneau}
            Q46={this.state.answers.Q46}
            Q47={this.state.answers.Q47}
            isPrime={
              this.state.answers.Q1 == "primeHellio" ||
              this.state.answers.Q30 == "primeHellio"
            }
            nonPrimeHellio={
              this.state.answers.Q45 === "Système solaire combiné" ||
              this.state.answers.Q45 === "PAC hybride air/eau"
            }
            codePromo={this.state.codePromo}
            handleCodePromo={this.handleCodePromo}
            validCodePromo={this.validCodePromo}
            resultCodePromo={this.state.resultCodePromo}
            bodyData={this.renderBodyData(this.state.success)}
          ></Success>
        );
    }
  }

  componentWillReceiveProps() {
    const History = window.history.state;
    this.setState({
      ...History,
    });
  }

  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_load",
        ...this.renderBodyData(),
      },
    });

    const leadsource = new URL(location.href).searchParams.get(
      "originedelapiste",
    );
    if (leadsource) {
      this.setState({ leadsource });
    }
    const params = new URL(location.href).searchParams;
    const utm_fields = [
      "utm_campaign",
      "utm_content",
      "utm_medium",
      "utm_source",
      "utm_term",
    ];

    utm_fields.forEach((utm_field) => {
      const value = params.get(utm_field);
      if (value) {
        this.setState({ [utm_field]: value });
      }
    });

    const contactParams = [
      "salutation",
      "firstname",
      "lastname",
      "email",
      "mobilephone",
    ];
    const capitalizeValue = ["salutation", "firstname", "lastname"];
    contactParams.forEach((param) => {
      const value = params.get(param);
      const contact = this.state.contact;
      if (value) {
        if (capitalizeValue.includes(param)) {
          contact[param] = value.charAt(0).toUpperCase() + value.slice(1);
        } else {
          contact[param] = value;
        }

        this.setState({ contact });
      }
    });

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    if (minDate.getDay() == 0 || minDate.getDay() == 6) {
      minDate.setDate(minDate.getDate() + ((1 + 7 - minDate.getDay()) % 7));
      this.setState({ date: minDate });
    }
    this.setState({ date: minDate });

    // let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
    // if(tomorrow.getDay == 0 || tomorrow.getDay == 6) {
    //   tomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + (1 + 7 - tomorrow.getDay()) % 7));
    // this.setState({ date: tomorrow });
    // } else {
    //   this.setState({ date: tomorrow });
    // }

    this.runDataLayersEachPage("Q1");
    const queryString = window.location.search
      .slice(1)
      .split("&")
      .map((item) => item.split("="));
    const path = window.location.pathname;
    if (dataRoutesQ1[path] && !leadsource) {
      const ANS = this.state.answers;
      ANS.Q1 = dataRoutesQ1[path];
      this.setState({ answers: ANS });
      this.getSuccess(this.state.answers);
      this.runDataLayersEachPage(this.state.stepList[this.state.step + 1]);
      setTimeout(
        function () {
          this.runDataLayers(this.state.stepList[this.state.step]);
          this.nextURL();
          this.setState({ step: this.state.step + 1 });
        }.bind(this),
        0,
      );
    }
    if (this.props.dataItems.Q1.items.length == 1) {
      const ANS = this.state.answers;
      ANS.Q1 = this.props.dataItems.Q1.items[0].short;
      this.setState({ answers: ANS });
      this.getSuccess(this.state.answers);
      this.runDataLayersEachPage(this.state.stepList[this.state.step + 1]);
      setTimeout(
        function () {
          this.runDataLayers(this.state.stepList[this.state.step]);
          this.nextURL();
          this.setState({ step: this.state.step + 1 });
        }.bind(this),
        0,
      );
    }
  }

  render() {
    // console.clear();
    const bodyData = this.renderBodyData();
    const updateBodyData = {
      ...bodyData,
    };
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
      fileImposition: this.state.file,
      fileDevis: this.state.fileDevis,
      fileAudit: this.state.fileAudit,
    };

    console.log("bodyData_encoded", bodyData_encoded);
    const fileDevisUpload =
      this.state.fileDevis && this.state.fileDevis.length <= 700000
        ? this.state.fileDevis
        : undefined;
    const fileAuditUpload =
      this.state.fileAudit && this.state.fileAudit.length <= 700000
        ? this.state.fileAudit
        : undefined;
    const fileImpositionUpload =
      this.state.file && this.state.file.length <= 700000
        ? this.state.file
        : undefined;
    const updateBodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(updateBodyData),
        cryptoKEY,
      ).toString(),
      fileImposition: this.state.file,
      fileDevis: this.state.fileDevis,
      fileAudit: this.state.fileAudit,
    };
    const current = this.state.stepList[this.state.step];

    if (ENDPOINT !== "https://simulateurb2c.hellio.com/simulateurb2c") {
      // console.log('AGENCE laforet',this.state.agence? this.state.agence.value : null)
      // console.log('mail partenaire', this.state.partenaireMail)
      // console.log('email partenaire', this.state.partenaireMail)
      // console.log('pre_question', this.state.pre_question)
      // console.log('error', this.state.error);

      //  console.log('Body(encrypted)', {"data": CryptoJS.AES.encrypt(JSON.stringify(bodyData), cryptoKEY).toString(),
      // fileImposition: this.state.file,
      // fileDevis: this.state.fileDevis,
      // fileAudit: this.state.fileAudit,})
      // const updateBodyData_encoded = {"data": CryptoJS.AES.encrypt(JSON.stringify(updateBodyData), cryptoKEY).toString(),
      // fileImposition: this.state.file,
      // fileDevis: this.state.fileDevis,
      // fileAudit: this.state.fileAudit
      // }

      if (this.state.result) {
        console.log("updateTransactionBody", {
          transaction_id: this.state.result.data.hubspot_transactionId,
          transaction_description:
            this.state.result.data.hubspot_transaction.properties.description,
          transaction_document_leads:
            this.state.result.data.hubspot_transaction.properties
              .document_leads,
          user_indications: this.state.comment,
          fileImposition: this.state.file,
          user_firstname: this.state.contact.firstname,
          user_lastname: this.state.contact.lastname,
          date_disponibilite_rappel: this.state.date.toLocaleDateString("fr"),
          creneau_disponibilite_rappel: this.state.creneau,
          partenariat_poitou: this.state.poitou,
        });
      }

      console.log("result from updateTransaction", this.state.patchResult);
      // console.log('AGENCE laforet',this.state.agence? this.state.agence.value : null)
      // console.log('mail partenaire', this.state.partenaireMail)
      // console.log('email partenaire', this.state.partenaireMail)
      // console.log('pre_question', this.state.pre_question)
      //  console.log('PatchBody(encrypted)', updateBodyData_encoded)
      // console.log('marque', this.props.marque)
      // console.log('creneau', this.state.creneau)
      // console.log('date', this.state.date)
      // console.log(this.state.stepList)
      // console.log(this.props.dataItems['Q1']['items'].length == 1);
      // console.log('PatchBody(encrypted)', updateBodyData_encoded)
      console.log("bodyData", bodyData);
      console.log("utm_campaign", this.state.utm_campaign);
      console.log("utm_medium", this.state.utm_medium);
      console.log("utm_source", this.state.utm_source);
      console.log("patchBody", updateBodyData);
      // console.log('urlQ1', this.state.urlQ1)
      console.log("window", window.location);
      //   console.log('props logoEtreRappele', this.props.logoEtreRappele)
      console.log(`Success: ${this.state.success}`);
      //   console.log(this.state.stepList.join(' , '))
      console.log(this.state.answers);
      //  console.log('getinfos bodyData', this.renderBodyData())
      console.log("result from API", this.state.result);
      // console.log('patch bodyData', this.state.patchBody);
      // console.log('patch body encrypted',  { "data": CryptoJS.AES.encrypt(JSON.stringify(this.state.patchBody), cryptoKEY).toString() })
      console.log("CodePromo", this.state.codePromo);
      console.log("ResultCodePromo", this.state.resultCodePromo);
      console.log("BodyCodePromo", {
        transaction_id: this.state.transaction_id,
        code: this.state.codePromo,
        reste_a_charge: this.state.result.data
          ? this.state.result.data["Reste à charge unitaire (€)"]
          : undefined,
      });
      console.log("endpoint:" + `${ENDPOINT}`);
      //   console.log('file impo', this.state.file)
      //   console.log('current Parcours', this.state.currentParcours)
      //   console.log('fileAudit', this.state.fileAudit)
      //   console.log('fileDevis', this.state.fileDevis)
      console.log("errorMessage", this.state.errorMessage);
    }

    const path = dataRoutesQ1[`${window.location.pathname}`]
      ? window.location.pathname
      : "/";
    // console.log(window.location);
    // console.log('RevenuArea', this.state.revenuArea)

    if (this.state.step === 0) {
      window.history.pushState(
        {
          step: 0,
        },
        undefined,
        `${path}${window.location.search}`,
      );
    }
    // console.log(this.state.stepList[this.state.step]);
    // console.log('id', this.props.id)
    let historyStep = window.history.state.step;
    if (!historyStep) {
      historyStep = this.state.step;
    }
    // localStorage.setItem('step', historyStep)
    // const localStep = localStorage.getItem('step');
    // console.log('local storage step', historyStep)
    const listChaudiere = ["Q23", "Q34", "Q25"];
    const nonPrimeHellio =
      (this.state.answers.Q45 === "Système solaire combiné" ||
        this.state.answers.Q45 === "PAC hybride air/eau") &&
      listChaudiere.includes(this.state.stepList[historyStep]);

    if (current === "Success") {
      return (
        <div>
          <ProgressBar
            step={this.state.step}
            stepList={this.state.stepList}
            moduleData={this.props.moduleData}
            currentStepName={this.state.stepList[this.state.step]}
            reset={this.reset}
          />
          <button
            className="btn-icon btn-init is-hidden-mobile"
            onClick={this.reset}
          >
            Réinitialiser
            <img src={init} alt="Réinitialiser" className="pl-5" />
          </button>
          <div className="whiteBoxSuccess">
            <div>{this.renderLead(this.state.stepList[historyStep])}</div>
          </div>
        </div>
      );
    } else if (current === "Q38") {
      return (
        <div>
          <ProgressBar
            step={this.state.step}
            stepList={this.state.stepList}
            moduleData={this.props.moduleData}
            currentStepName={this.state.stepList[this.state.step]}
            reset={this.reset}
          />

          <div className="whiteBoxWeb mt-10">
            <button
              className="btn-icon btn-init is-hidden-mobile"
              onClick={this.reset}
            >
              Réinitialiser
              <img src={init} alt="Réinitialiser" className="pl-5" />
            </button>
            <div>
              <QR38
                patchAPI={this.patchAPI}
                lastStep={this.lastStep}
                logoEtreRappele={this.props.logoEtreRappele}
                reset={this.reset}
              ></QR38>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ProgressBar
            step={this.state.step}
            stepList={this.state.stepList}
            moduleData={this.props.moduleData}
            currentStepName={this.state.stepList[this.state.step]}
          />
          <div className="whiteBoxWeb">
            <button
              className="btn-icon btn-init is-hidden-mobile"
              onClick={this.reset}
            >
              Réinitialiser
              <img src={init} alt="Réinitialiser" className="pl-5" />
            </button>

            {/* Success: {this.state.success} <br/>
                    StepList: {this.state.stepList.join(' , ')} staging */}
            <div>
              <Greeting
                moduleData={this.props.moduleData}
                step={this.state.step}
                currentStepName={this.state.stepList[this.state.step]}
                dataItems={this.props.dataItems}
                nonPrimeHellio={nonPrimeHellio}
                answers={this.state.answers}
              />
              <div>{this.renderLead(this.state.stepList[historyStep])}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withParams(Main);
